@tailwind base;
@tailwind components;
@tailwind utilities;

#gradient-canvas {
  --gradient-color-1: #bde1ff;
  --gradient-color-2: #6ca0f4;
  --gradient-color-3: #e2d8ee;
  --gradient-color-4: #94c8ff;
}
